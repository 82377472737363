import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import Specialties from "../components/Repeating/Specialties";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonVerticalLines from "../components/Button/ButtonVerticalLines";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Buying a Home in Minnesota | Thistle Financial"
        description="Get help every step of the way when buying a home in Minnesota—whether you're just starting to look, ready to make an offer, or are doing research."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        className="mb-16 md:mb-20"
      >
        <h1>Buying a Home in Minnesota</h1>
        <p>
          Are you thinking of purchasing a home? Get help every step of the
          way—whether you’re just starting to look at what’s on the market,
          ready to make an offer, or doing a little research before moving
          forward.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="text-center mb-8 md:mb-10">
            <h2>Where Are You in the Home-Buying Process?</h2>
          </header>
          <div className="grid md:grid-cols-3 gap-y-12 md:gap-x-5 lg:gap-x-10">
            <div>
              <GatsbyImage
                image={data.researching.childImageSharp.gatsbyImageData}
                className="mb-6"
              />
              <h3 className="heading-four mb-3">I’m Researching</h3>
              <p className="mb-0">
                Find out how much home you can afford, run a mortgage
                calculator, and more.
              </p>
            </div>
            <div>
              <GatsbyImage
                image={data.openHouse.childImageSharp.gatsbyImageData}
                className="mb-6"
              />
              <h3 className="heading-four mb-3">I’m Looking at Open Houses</h3>
              <p className="mb-0">
                Get pre-approved and you'll be in a better position to negotiate
                with sellers.
              </p>
            </div>
            <div>
              <GatsbyImage
                image={data.offer.childImageSharp.gatsbyImageData}
                className="mb-6"
              />
              <h3 className="heading-four mb-3">I’m Ready to Make an Offer</h3>
              <p className="mb-0">
                Get help selecting the loan that's right for you and complete a
                full application.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-10 lg:gap-x-20 items-center">
            <div className="md:m-auto">
              <GatsbyImage image={data.loan.childImageSharp.gatsbyImageData} />
            </div>
            <div>
              <h3 className="heading-two">Loan Programs</h3>
              <p>
                A mortgage isn't “one size fits all.” That's why we work with
                multiple lenders to find a home loan option that's comfortable,
                manageable, and right for you.
              </p>
              <ButtonVerticalLines
                href="/home-loan-programs-minnesota/"
                text="Compare Loans"
              />
            </div>
          </div>
        </div>
      </section>

      <Specialties />
      <Testimonials />
      <Resources />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Buyers.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Buyers.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "2.0 Purchase/1.0 Hero .jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
    researching: file(
      relativePath: { eq: "2.0 Purchase/2.1 researching.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 373)
      }
    }
    openHouse: file(relativePath: { eq: "2.0 Purchase/2.2 open house.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 373)
      }
    }
    offer: file(relativePath: { eq: "2.0 Purchase/2.3 offer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 373)
      }
    }
    loan: file(relativePath: { eq: "2.0 Purchase/Loan programs.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;
export default Page;
